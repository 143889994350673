<template>
  <div class="boxPage">
    <div class="banner">
      <div class="pimg">
        <img :src="img" alt="" />
        <!-- <img src="@/assets/img/markcamp/banner.png" alt="" /> -->
        <div v-if="allmsg.isSign == 0 && allmsg.signStatus == 2" @click="goOrders()" class="btn">
          立即参加
        </div>
      </div>
    </div>
    <div class="studylink">
      <div class="studymain">
        <img class="stutil" src="@/assets/img/markcamp/til1.png" alt="" />
      </div>
      <div class="link">
        <div class="linkleft">
          <div v-for="(item, index) in list" :key="index" :class="index + 1 == list.length ? 'hj' : 'hj hj1'">
            <div class="hjname">第{{ index + 1 }}环节</div>
            <div class="shu">
              <div class="gd">{{ index + 1 }}</div>
            </div>
          </div>
          <!-- <div class="hj hj1">
            <div class="hjname">第一环节</div>
            <div class="shu">
              <div class="gd"></div>
            </div>
          </div>
          <div class="hj">
            <div class="hjname">第一环节</div>
            <div class="shu">
              <div class="gd"></div>
            </div>
          </div> -->
        </div>
        <div class="linkright">
          <div v-for="(items, index) in list" :key="index" class="hjmain">
            <div class="pic">
              <img :src="items.image" alt="" />
            </div>
            <div class="hjmsg">
              <div class="til">{{ items.name }}</div>
              <div class="tilmsg">{{ items.introduce }}</div>
              <div class="classtype">
                <img src="@/assets/img/markcamp/zb.png" alt="" />
              </div>
              <div v-if="items.tasks.length > 0 && items.tasks[0].isLive == 0" class="status">未解锁</div>
              <div v-if="items.tasks.length == 0" class="status">未解锁</div>
              <div @click="goSp(items)" v-if="items.tasks.length > 0 && items.tasks[0].isLive != 0" class="statuss">
                开始学习
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xly">
      <div class="studymain">
        <img class="stutil" src="@/assets/img/markcamp/til2.png" alt="" />
      </div>
      <div class="msgs" v-html="ms"></div>
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifHave" width="30%" center>
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAllDetail } from '@/api/markcamp'
import { getInfo } from '@/api/cookies'
import Vue from 'vue'

import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()
export default {
  data() {
    return {
      userInfo: {},
      list: [],
      ms: '',
      img: '',
      allmsg: '',
      ifHave: false,
    }
  },
  async created() {
    this.trainingId = await this.$route.query.id
    this.userInfo = await getInfo()

    this.getList()
  },
  methods: {
    /* 获取列表 */
    getList() {
      getAllDetail({
        userid: this.userInfo ? this.userInfo.id : undefined,
        trainingId: this.trainingId ? this.trainingId : undefined,
      }).then((res) => {
        this.allmsg = res.data
        this.list = res.data.links
        this.ms = res.data.detail
        this.img = res.data.pcImg
      })
    },
    goSp(item) {
      // localStorage.setItem('classrow', JSON.stringify(item))
      // localStorage.setItem('allmsg', JSON.stringify(this.allmsg))
      if (this.allmsg.isSign == 1) {
        // this.$router.push({
        //   path: '/markcamp/tarn',
        //   // query: {
        //   //   classrow: Vue.prototype.encrypt(JSON.stringify(item)),
        //   //   allmsg: Vue.prototype.encrypt(JSON.stringify(this.allmsg)),
        //   // },
        // })
        window.open(`/markcamp/tarn?id=${this.trainingId}&tid=${item.id}`, '_blank')
        // this.$router.push(`/markcamp/tarn?id=${this.trainingId}&tid=${item.id}`)
      } else {
        this.$message.warning('您还未购买')
      }

      // this.$router.push(`/markcamp/tarn?classrow=${JSON.stringify(item)}`)
    },
    goOrders() {
      this.ifHave = true
    },
    /* 立即购买 */
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(this.allmsg.id, this.userInfo.id, this.allmsg.title, 11, this.allmsg.title)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  background: #ffffff;
  padding: 46px 0;

  .pimg {
    width: 1200px;
    height: 500px;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .btn {
      position: absolute;
      width: 193px;
      height: 43px;
      background: #ff5e51;
      border-radius: 4px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 43px;
      letter-spacing: 1px;
      text-align: center;
      right: 79px;
      bottom: 55px;
      cursor: pointer;
    }
  }
}

.studylink {
  width: 100%;
  padding: 32px 0 100px 0;
  background: #f8faff;

  .studymain {
    width: 65%;
    margin: 0 auto;

    .stutil {
      width: 68%;
      margin: 0 auto;
    }
  }
}

.link {
  width: 1240px;
  margin: 0 auto;
  display: flex;
  margin-top: 45px;

  .linkleft {
    display: flex;
    flex-direction: column;

    .hj {
      display: flex;

      .hjname {
        width: 90px;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #1061ff;
        margin-right: 37px;
        // margin-top: 12px;
      }

      .gd {
        width: 50px;
        height: 50px;
        background: url('~@/assets/img/markcamp/jl.png');
        position: absolute;
        right: -22px;
        top: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #1061ff;
      }

      .shu {
        position: relative;
        height: 190px;
        border-left: 5px solid #2e74ff;
        margin-right: 20px;
      }
    }

    .hj:nth-child(1) {
      margin-top: 30px;
    }

    .hj1 {
      .shu {
        position: relative;
        height: 265px;
        border-left: 5px solid #2e74ff;
        margin-right: 20px;
      }
    }
  }

  .linkright {
    margin-left: 50px;

    .hjmain {
      width: 1041px;
      height: 224px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(212, 212, 212, 0.37);
      border-radius: 8px;
      display: flex;
      margin-top: 40px;

      .pic {
        width: 307px;
        height: 181px;
        margin: 24px 25px 0 15px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .hjmsg {
        width: 100%;
        margin-left: 25px;
        margin-top: 27px;
        position: relative;

        // flex-shrink: 0;
        .til {
          width: 550px;
          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          line-height: 26px;
          letter-spacing: 1px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .tilmsg {
          width: 550px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #555555;
          line-height: 21px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-top: 14px;
        }

        .classtype {
          width: 64px;
          height: 30px;
          // background: #fff7f0;
          border-radius: 12px;
          margin-top: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .status {
          width: 92px;
          background: #6389fc;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ffffff;
          border-radius: 34px;
          margin-top: 20px;
          text-align: center;
          position: absolute;
          right: 55px;
        }

        .statuss {
          width: 92px;
          background: #2871ff;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ffffff;
          border-radius: 34px;
          margin-top: 20px;
          text-align: center;
          position: absolute;
          right: 55px;
          cursor: pointer;
        }
      }
    }

    .hjmain:nth-child(1) {
      margin-top: 0;
    }
  }
}

.xly {
  width: 100%;
  padding: 49px 0 0 0;
  background: #ffffff;

  .studymain {
    width: 65%;
    margin: 0 auto;

    .stutil {
      width: 68%;
      margin: 0 auto;
    }
  }

  /deep/ .msgs {
    width: 1228px;
    margin: 0 auto;
    margin-top: 50px;
    padding-bottom: 20px;

    img {
      max-width: 100%;
    }
  }
}</style>
